const theme = {
  colors: {
    turqoise: '#00AFA4',
    clearturqoise: '#EBF8F6',
    highlightedturqoise: '#40C3BB',
    white: '#fff',
    lightgray: '#C8C8C8',
    black: '#333',
    darkgray: '#545860',
    blackhighlight: '#4c5057',
    gray: '#8B8B8B',
    background: '#F9F9FA',
    blue: '#337ab7',
    layout: '#F9F9FA',
    font: {
      white: '#fff',
      teal: '#00AFA4',
      tealhighlight: '#40C3BB',
      gray: '#686868',
      darkgray: '#C8C8C8',
      lightgray: '#4c5057',
      fadedgray: '#8B8B8B',
    },
  },
  space: {
    '1px': 1,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    8: 32,
    10: 40,
    12: 48,
    16: 64,
    20: 80,
    24: 96,
    32: 128,
    40: 160,
    48: 192,
    56: 224,
    64: 256,
  },
  font: {
    xs: '.75rem',
    sm: '.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '5rem',
  },
  breakpoints: {
    xs: 375,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1440,
  },
}

const media = (breakpoint) =>
  `@media (min-width: ${theme.breakpoints[breakpoint] || breakpoint}px)`

export default theme
export { theme, media }
