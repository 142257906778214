import React from 'react'
import BannerLayout from './banner.styles'
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from 'prop-types'

const Banner = ({ open, onCloseClick }) => {
  return (
    <>
      {open && (
        <BannerLayout>
          <BannerLayout.text>
            Thanks for visiting! We hope you enjoy your Sitelio experience. Just
            to let you know you visited one of our paid endorsements before
            arriving at our site.
          </BannerLayout.text>
          <BannerLayout.button onClick={onCloseClick}>
            <ClearIcon />
          </BannerLayout.button>
        </BannerLayout>
      )}
    </>
  )
}

Banner.propTypes = {
  open: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
}

export default Banner
