/**
 *
 */

import React from 'react'
import callToActionImage from '../../images/logo-webcom.webp'
import HeaderContainer from '../navigation/header.styles'

const PopPup = ({ callToAction, setCallToAction, top }) => {
  return (
    <HeaderContainer.callToActionContainer isOpen={callToAction} top={top}>
        <HeaderContainer.callToActionBar onClick={() => setCallToAction(false)}>
          <span>X</span>
        </HeaderContainer.callToActionBar>
        <HeaderContainer.callToActionContent>
          <HeaderContainer.callToActionImage src={callToActionImage} alt='call to action' />
          <HeaderContainer.callToActionDescription>Our partner brand Web.com delivers everything you need to grow your business. Web.com One Stop. All Done.</HeaderContainer.callToActionDescription>
          <HeaderContainer.callToActionRedirectText>You will now be redirected to Web.com</HeaderContainer.callToActionRedirectText>
        </HeaderContainer.callToActionContent>
      </HeaderContainer.callToActionContainer>
  )
}

export default PopPup
