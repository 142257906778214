import React, {useState} from 'react'
import HeaderContainer from './header.styles'
import MenuContainer from './menu.styles'
import LogoWhite from '../../images/logo-white.svg'
import Logo from '../../images/logo.svg'
import styled from 'styled-components'
import theme from '../../helpers/theme'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import PopPup from '../pop-pup'

const NavigationContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  background-color: ${theme.colors.white};
`

const Navigation = ({ handleShift, isWhiteLogo = false }) => {
  const [callToAction, setCallToAction] = useState(false)
  const isSamePage = (navigateTo) => {
    if (typeof window !== 'undefined' && location.pathname === navigateTo) {
      handleShift()
    }
  }

  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
  }, 5000)
  }

  const redirectWebDotComDisplayBanner = (websiteUrl)=>{
    setCallToAction(true)
    redirectWebdotcom(websiteUrl)
  }

  return (
    <>
      <MenuContainer>
        <MenuContainer.content>
          <MenuContainer.section>
            <MenuContainer.homeTitle onClick={() => isSamePage('/')} to='/'>
              HOME
            </MenuContainer.homeTitle>
          </MenuContainer.section>
          <MenuContainer.section>
            <MenuContainer.title>PRODUCT</MenuContainer.title>
            <MenuContainer.subtitle 
              to='#'
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.websites'
              data-element-id='sitelio-header-websites-mobile'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites')}>
              Websites
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              to='#'
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.marketing'
              data-element-id='sitelio-header-marketing-mobile'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/online-marketing')}>
                Marketing
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              to='#'
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.domains'
              data-element-id='sitelio-header-domains-mobile'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/domains')}>
                Domains
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              to='#'
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.hosting'
              data-element-id='sitelio-header-hosting-mobile'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites/hosting')}>
                Hosting
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              to='#'
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.security'
              data-element-id='sitelio-header-security-mobile'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/website-security')}>
                Security
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              to='#'
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.expert-email'
              data-element-id='sitelio-header-email-mobile'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/email-service')}>
                Email
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              to='#'
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.expert-services'
              data-element-id='sitelio-header-expert-services-mobile'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites/pro-website-services')}>
                Expert Services
            </MenuContainer.subtitle>
          </MenuContainer.section>
          <MenuContainer.section>
            <MenuContainer.title>CUSTOMERS</MenuContainer.title>
            <MenuContainer.subtitle
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.signin'
              data-element-id='sitelio-header-signin'
              to={process.env.GATSBY_LOGIN_URL}
            >
              Log in
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.signup'
              data-element-id='sitelio-header-signup-mobile'
              to='#'
              onClick={() => redirectWebDotComDisplayBanner('https://www.web.com/websites/online-store-builder#packages')}
            >
              Sign Up
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              data-element-location={DataElementLocations.HEADER}
              data-element-label={`sitelio-header-links-support`}
              data-element-id={`sitelio-links-support`}
              target={'_blank'}
              to={process.env.GATSBY_SUPPORT_URL}
            >
              Support
            </MenuContainer.subtitle>
          </MenuContainer.section>
          <MenuContainer.section>
            <MenuContainer.title>COMPANY</MenuContainer.title>
            <MenuContainer.subtitle
              onClick={() => isSamePage('/terms')}
              to='/terms'
            >
              Terms of Service
            </MenuContainer.subtitle>
            <MenuContainer.subtitle
              target='_blank'
              href=' https://newfold.com/privacy-center '
            >
              Privacy Notice
            </MenuContainer.subtitle>
          </MenuContainer.section>
        </MenuContainer.content>
      </MenuContainer>
      <NavigationContainer>
        <HeaderContainer isWhiteLogo={isWhiteLogo}>
          <HeaderContainer.logoContainer>
            <HeaderContainer.logoLink
              data-element-location={DataElementLocations.HEADER}
              data-element-label='navigation.logo'
              data-element-id='sitelio-navigation-logo'
              to='/'
            >
              {isWhiteLogo ? (
                <HeaderContainer.logo src={LogoWhite} alt='Sitelio Logo' />
              ) : (
                <HeaderContainer.logo src={Logo} alt='Sitelio Logo' />
              )}
            </HeaderContainer.logoLink>
          </HeaderContainer.logoContainer>
          <HeaderContainer.screenReaderText>
            If you are using assistive technology and are unable to read any
            part of the Sitelio.com website, or otherwise have difficulties
            using the Sitelio.com website, please call 844-211-7894 and our
            customer service team will assist you.
          </HeaderContainer.screenReaderText>
          <HeaderContainer.linkContainer>
          <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.websites'
              data-element-id='sitelio-header-websites'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/websites')
              }}>
                Websites
              </span>
            </HeaderContainer.loginLink>
            <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.marketing'
              data-element-id='sitelio-header-marketing'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/online-marketing')
              }}>
                Marketing
              </span>
            </HeaderContainer.loginLink>
            <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.domains'
              data-element-id='sitelio-header-domains'
            >
              <span onClick={() => {
                  setCallToAction(true), redirectWebdotcom('https://www.web.com/domains')
                }}>
                  Domains
              </span>
            </HeaderContainer.loginLink>
            <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.hosting'
              data-element-id='sitelio-header-hosting'
            >
              <span onClick={() => {
                  setCallToAction(true), redirectWebdotcom('https://www.web.com/websites/hosting')
              }}>
                Hosting
              </span>
            </HeaderContainer.loginLink>
            <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.security'
              data-element-id='sitelio-header-security'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/website-security')
              }}>
                Security
              </span>
            </HeaderContainer.loginLink>
            <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.email'
              data-element-id='sitelio-header-email'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/email-service')
              }}>
                Email
              </span>
            </HeaderContainer.loginLink>
            <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.expert-services'
              data-element-id='sitelio-header-expert-services'
            >
              <span onClick={() => {
                setCallToAction(true), redirectWebdotcom('https://www.web.com/websites/pro-website-services')
              }}>
                Expert Services
              </span>
            </HeaderContainer.loginLink>
            <HeaderContainer.loginLink
              isWhiteLogo={isWhiteLogo}
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.signin'
              data-element-id='sitelio-header-signin-main'
              to={process.env.GATSBY_LOGIN_URL}
            >
                LOG IN
            </HeaderContainer.loginLink>
            <HeaderContainer.iconContainer onClick={() => handleShift()}>
              <HeaderContainer.iconLabel isWhiteLogo={isWhiteLogo}>
                MENU
              </HeaderContainer.iconLabel>
              <HeaderContainer.icon isWhiteLogo={isWhiteLogo} />
            </HeaderContainer.iconContainer>
          </HeaderContainer.linkContainer>
        </HeaderContainer>
      </NavigationContainer>
      <HeaderContainer.popupContainer>
        <PopPup callToAction={callToAction} setCallToAction={setCallToAction} />
      </HeaderContainer.popupContainer>
    </>
  )
}

export default Navigation
