import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'
import Link from 'gatsby-link'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'

const Layout = styled.div`
  padding-bottom: ${(props) =>
    props.bannerOpen ? `${theme.space[20]}px` : `${theme.space[12]}px`};
  padding-top: ${theme.space[12]}px;
  position: relative;
  background-color: ${theme.colors.darkgray};
  width: 100%;
  min-height: ${(props) => (props.isTallFooter ? '51vh' : 'none')};
`

Layout.footerContainer = styled.div`
  padding-right: ${theme.space[4]}px;
  padding-left: ${theme.space[4]}px;
  overflow-y: hidden;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: ${theme.space[3]}px;

  ${media('md')} {
    padding-bottom: ${theme.space[6]}px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

Layout.navContainer = styled.div`
  float: left;
  display: none;
  flex-direction: row;

  ${media('md')} {
    display: flex;
    width: 550px;
    justify-content: space-around;
  }

  ${media('lg')} {
    display: flex;
    width: 600px;
    margin-left: -${theme.space[12]}px;
  }
`

Layout.navColumn = styled.div`
  padding-left: ${theme.space[8]}px;
  display: flex;
  flex-direction: column;
  text-align: left;
`

Layout.navTitle = styled.span`
  color: ${theme.colors.font.white};
  font-size: ${theme.font['sm']};
  margin-bottom: ${theme.space[4]}px;
  text-transform: uppercase;
`

Layout.navSubOption = styled(Link)`
  color: ${theme.colors.font.darkgray};
  font-size: ${theme.font['xs']};
  text-decoration: none;
  display: inline;
  margin: ${theme.space[3]}px auto;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.white};
  }

  ${media('md')} {
    margin: ${theme.space[1]}px auto ${theme.space[1]}px 0;
  }
`

Layout.personalInfoDesktop = styled.span`
  color: ${theme.colors.font.darkgray};
  font-size: ${theme.font['xs']};
  margin-top: ${theme.space[1]}px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.white};
  }
`

Layout.linkContainer = styled.div`
  display: flex;
  flex-direction: column;
`

Layout.logoLink = styled(Link)``

Layout.LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  ${media('md')} {
    justify-content: space-evenly;
  }
`

Layout.rightContainer = styled.div`
  float: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: none;
  ${media('md')} {
    display: flex;
    float: right;
    text-align: right;
    align-items: flex-start;
  }

  ${media(992)} {
    width: initial;
    align-items: center;
  }
`

Layout.FBIcon = styled(FacebookIcon)`
  &.MuiSvgIcon-root {
    color: ${theme.colors.font.darkgray};
    font-size: ${theme.font['4xl']};
    margin-right: ${theme.space[4]}px;
    ${media('md')} {
      margin: 0;
    }
  }

  &:hover {
    color: ${theme.colors.turqoise};
  }
`

Layout.tIcon = styled(TwitterIcon)`
  &.MuiSvgIcon-root {
    color: ${theme.colors.font.darkgray};
    font-size: ${theme.font['4xl']};
    margin-left: ${theme.space[4]}px;
    ${media('md')} {
      margin: 0;
    }
  }

  &:hover {
    color: ${theme.colors.turqoise};
  }
`

Layout.copyright = styled.span`
  color: ${theme.colors.font.white};
  font-size: ${theme.font['xs']};
  margin-top: ${theme.space[5]}px;
`

Layout.personalInfoMobile = styled.span`
  display: initial;
  color: ${theme.colors.font.white};
  font-size: ${theme.font['xs']};
  padding-top: ${theme.space[5]}px;
  padding-bottom: ${theme.space[6]}px;
  overflow: hidden;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.white};
  }

  ${media('md')} {
    display: none;
  }
`

Layout.rightsContainer = styled.div`
  background-color: ${theme.colors.font.lightgray};
  position: absolute;
  color: white;
  font-size: ${theme.font['sm']};
  float: left;
  bottom: ${(props) => (props.bannerOpen ? '50px' : '0')};
  left: 0;
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: ${theme.colors.font.lightgray};
  ${media('md')} {
    text-align: initial;
  }
`
Layout.rightsReserved = styled.div`
  margin-right: auto;
  margin-left: auto;
  transform: translateY(50%);
  padding: 0;

  ${media('md')} {
    padding-left: ${theme.space[12]}px;
  }
  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
  ${media('lg')} {
    padding-left: 0;
  }
`

Layout.endContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 50px;
`

export default Layout
