import { createGlobalStyle } from 'styled-components'
import theme from '../../helpers/theme'

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans';
    overflow-x: hidden;
    background-color: ${theme.colors.layout};
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
`

export default GlobalStyle
