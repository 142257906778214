import styled from 'styled-components'
import Link from 'gatsby-link'
import theme from '../../helpers/theme'

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 100%;
  width: 250px;
  height: 100%;
  background-color: ${theme.colors.black};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: ${theme.space[4]}px 0;
  width: 100%;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${theme.space[3]}px 0px;
`

const HomeTitle = styled(Link)`
  color: ${theme.colors.white};
  padding: 0;
  width: 100%;
  padding: ${theme.space[1]}px ${theme.space[10]}px;
  font-size: ${theme.font.sm};
  text-decoration: none;
  :hover {
    background-color: ${theme.colors.blackhighlight};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
`

const Title = styled.span`
  color: ${theme.colors.white};
  padding: 0;
  width: 100%;
  padding: ${theme.space[1]}px ${theme.space[10]}px;
  font-size: ${theme.font.sm};
  :hover {
    background-color: ${theme.colors.blackhighlight};
    transition: all 0.2s ease-in-out;
  }
`

const Subtitle = styled(Link)`
  color: ${theme.colors.lightgray};
  width: 100%;
  padding: ${theme.space[1]}px ${theme.space[10]}px;
  font-size: ${theme.font.xs};
  text-decoration: none;
  :hover {
    background-color: ${theme.colors.blackhighlight};
    color: ${theme.colors.turqoise};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
`

Container.content = Content
Container.homeTitle = HomeTitle
Container.title = Title
Container.subtitle = Subtitle
Container.section = Section
export default Container
