import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import GlobalStyle from './global.styles'
import Navigation from '../navigation/index'
import styled from 'styled-components'
import Footer from '../footer'
import Banner from '../banner'

const BodyDiv = styled.div`
  position: relative;
  transition: transform 0.2s linear;
  scroll-behavior: smooth;

  ${({ active }) => active && 'transform: translateX(-250px);'}
`

const Layout = ({ isWhiteLogo = false, isTallFooter = false, children }) => {
  const [shift, setShift] = useState(false)
  const [open, setOpen] = useState(true)

  const detectShift = () => {
    setShift(!shift)
  }

  const onCloseClick = () => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('bannerClosed', true)
      setOpen(false)
    }
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      JSON.parse(window.sessionStorage.getItem('bannerClosed'))
    ) {
      setOpen(false)
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <BodyDiv active={shift}>
        <Navigation isWhiteLogo={isWhiteLogo} handleShift={detectShift} />
        {children}
        <Banner open={open} onCloseClick={onCloseClick} />
        <Footer isTallFooter={isTallFooter} bannerOpen={open} />
      </BodyDiv>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isTallFooter: PropTypes.bool,
  isWhiteLogo: PropTypes.bool,
}

export default Layout
