import styled from 'styled-components'
import Link from 'gatsby-link'
import MenuIcon from '@material-ui/icons/MenuTwoTone'
import theme, { media } from '../../helpers/theme'

const Container = styled.div`
  display: flex;
  position: ${(props) => (props.isWhiteLogo ? 'absolute' : 'relative')};
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-right: ${theme.space[4]}px;
  padding-left: ${theme.space[4]}px;
  width: 100%;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

const ScreenReaderText = styled.div.attrs(() => ({
  className: 'screen-reader-text',
  ariaLabel: 'Sitelio Assistance Info',
}))`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
`

const LogoContainer = styled.a``
const LogoLink = styled(Link)``
const Logo = styled.img`
  width: 131px;
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

const LoginLink = styled(Link)`
  display: none;
  text-decoration: none;
  margin-right: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props) =>
    props.isWhiteLogo ? theme.colors.white : theme.colors.blackhighlight};
  :hover {
    color: ${theme.colors.turqoise};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  ${media(768)} {
    display: unset;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  ${media(1024)} {
    display: none;
  }

  :hover {
    * {
      color: ${theme.colors.turqoise};
      transition: all 0.2s ease-in-out;
    }
    cursor: pointer;
  }
`
const IconLabel = styled.span`
  color: ${(props) =>
    props.isWhiteLogo ? theme.colors.white : theme.colors.blackhighlight};
`
const Icon = styled(MenuIcon)`
  color: ${(props) =>
    props.isWhiteLogo ? theme.colors.white : theme.colors.blackhighlight};
`

Container.logoContainer = LogoContainer
Container.screenReaderText = ScreenReaderText
Container.logo = Logo
Container.logoLink = LogoLink
Container.linkContainer = LinkContainer
Container.loginLink = LoginLink
Container.iconContainer = IconContainer
Container.iconLabel = IconLabel
Container.icon = Icon

Container.popupContainer = styled.div`
  position: absolute;
  max-width: 400px;
  width:100%;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  z-index:501;

  @media (max-width:767px){
    top:50px;
    left:50%;
    transform:translateX(-50%);
    margin-left:250px;
    max-width:400px;
  }
`

Container.callToActionContainer = styled.div`
  width: 400px;
  height: 210px;
  background: white;
  padding: 0px;
  bottom: 0;
  z-index: 100;
  color: #000;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  @media (max-width:767px){
    z-index:501;
    width:100%;
    max-width:400px;
  }
  `

Container.callToActionBar = styled.div`
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  padding: 0px;
  border-bottom: 1px solid #d3d3d3;
  text-align: end;
  padding-right: 15px;
  font-size: 17px;
  padding-top: 8px;
  font-weight: bold;
  cursor: pointer;
`

Container.callToActionContent = styled.div`
  width: 100%;
  padding: 10px 20px;
`

Container.callToActionDescription = styled.span`
  font-size: 15px;
  font-weight: bold;
  text-aling: start;
  display: block;
  margin-top: 10px;
  margin-bottom: 5px
  color: black;
`

Container.callToActionRedirectText = styled.span`
  font-size: 12px;
  display: block;
  color: black;
`

Container.callToActionImage = styled.img`
  width: 100%;
  max-width: 200px;
`

export default Container
