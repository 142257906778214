import React from 'react'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import Layout from './footer.styles'
import PropTypes from 'prop-types'

const Footer = ({ isTallFooter, bannerOpen }) => {
  const isSamePage = (navigateTo) => {
    if (
      typeof window !== 'undefined' &&
      window.location.pathname === navigateTo
    ) {
      window.scrollTo(0, 0)
    }
  }

  return (
    <Layout bannerOpen={bannerOpen} isTallFooter={isTallFooter}>
      <Layout.footerContainer>
        <Layout.navContainer>
          <Layout.navColumn>
            <Layout.linkContainer>
              <Layout.navTitle>Company</Layout.navTitle>
              <Layout.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitelio-footer-links-terms`}
                data-element-id={`sitelio-links-terms`}
                onClick={() => isSamePage('/terms')}
                to={'/terms'}
              >
                Terms of Service
              </Layout.navSubOption>
            </Layout.linkContainer>
            <Layout.linkContainer>
              <Layout.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitelio-footer-links-privacy`}
                data-element-id={`sitelio-links-privacy`}
                target='_blank'
                href={' https://newfold.com/privacy-center '}
              >
                Privacy Notice
              </Layout.navSubOption>
            </Layout.linkContainer>
            <Layout.linkContainer>
              <Layout.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitelio-footer-links-abuse`}
                data-element-id={`sitelio-links-abuse`}
                onClick={() => isSamePage('/abuse')}
                to={'/abuse'}
              >
                Abuse
              </Layout.navSubOption>
            </Layout.linkContainer>
            <Layout.linkContainer>
              <Layout.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitelio-footer-links-personal-info`}
                data-element-id={`sitelio-links-personal-info-desktop`}
                target='_blank'
                href={'https://www.newfold.com/privacy-center/addendum-for-california-users'}
              >
                <Layout.personalInfoDesktop>
                  Do Not Sell My Personal Information
                </Layout.personalInfoDesktop>
              </Layout.navSubOption>
            </Layout.linkContainer>
            <Layout.linkContainer>
              <Layout.navSubOption
                data-element-location={DataElementLocations.FOOTER}
                data-element-label={`sitelio-footer-cookie-setting`}
                data-element-id={`sitelio-links-cookie-setting`}
                className={"ot-sdk-show-settings"}
              >
                <Layout.personalInfoDesktop>
                  Cookie settings
                </Layout.personalInfoDesktop>
              </Layout.navSubOption>
            </Layout.linkContainer>
          </Layout.navColumn>
        </Layout.navContainer>
        <Layout.rightContainer>
          <Layout.copyright>
            5335 Gate Pkwy, 2nd Floor,<br />
            Jacksonville, FL 32256 <br />
          </Layout.copyright>
          <Layout.navSubOption
            data-element-location={DataElementLocations.FOOTER}
            data-element-label={`sitelio-footer-links-personal-info`}
            data-element-id={`sitelio-links-personal-info-mobile`}
            target={'_blank'}
            href={'https://www.newfold.com/privacy-center/addendum-for-california-users'}
          >
            <Layout.personalInfoMobile>
              Do Not Sell My Personal Information
            </Layout.personalInfoMobile>
          </Layout.navSubOption>

          <Layout.navSubOption
            data-element-location={DataElementLocations.FOOTER}
            data-element-label={`sitelio-footer-links-cookie-info`}
            data-element-id={`sitelio-links-cookie-setting-mobile`}
            className={"ot-sdk-show-settings"}
          >
            <Layout.personalInfoMobile>
            Cookie settings
            </Layout.personalInfoMobile>
          </Layout.navSubOption>

        </Layout.rightContainer>
        <Layout.rightsContainer bannerOpen={bannerOpen}>
          <Layout.rightsReserved>
            © Sitelio.com 2021. All Rights Reserved.
          </Layout.rightsReserved>
        </Layout.rightsContainer>
        {bannerOpen && <Layout.endContainer />}
      </Layout.footerContainer>
    </Layout>
  )
}

Footer.propTypes = {
  isTallFooter: PropTypes.bool,
  bannerOpen: PropTypes.bool,
}

export default Footer
